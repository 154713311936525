import {
  apiUrls,
  buildUserEndpoint,
  buildUserAddressEndpoint,
  buildCreditScoreRefreshEndpoint,
  buildCreditScore3BDial,
  buildInvitationReferrerEndpoint,
  buildUserVerifySSNEndpoint,
  buildUpdateCredentialsEndpoint,
  buildAffiliatePixels,
  buildValidateEmailChange,
  buildVerifyPhoneEmailChange,
  buildSendCodeEmailChange,
  buildResetCredentialsEmailChange,
  buildGetDupeOTP,
  buildValidateDupeOTP,
  buildDupKBAPhoneVerification,
  buildDupKBASendCode,
  buildDupKBAPhoneActivateUser,
  buildVerifyEmailChange,
  buildSelfServeChangeEmail,
  buildVerifyPhoneChange,
  buildSelfServeChangePhone,
  buildUserIdVerificationEndpoint,
  buildUserIdVerificationSubmittedEndpoint,
  buildUserDetailsDMEndpoint,
  buildCoupons,
  buildUsersActivityEventInViewOffers,
  buildUsersActivityEventTypeInOfferModule,
  buildDecreseCreditCardBalance,
  buildAcknowledgePrescreen,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardResponseHandler,
  standardizedFetch,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';
import { setCStrack } from 'helpers/localstorage';
import { fetchTrkCookies } from 'externals/_services/www.service';
import { getSlapiApiKey } from 'helpers/env';
import {
  getMarketingCookies,
  getMarketingCookieAttr,
} from 'helpers/marketing.helper';
import { getCookieValue, setCookieWithExpiration } from 'helpers/cookie.helper';
import { getIovationBlackbox } from 'helpers/common';

export async function getUserProfile(vriVersion) {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    buildUserEndpoint(vriVersion),
    requestOptions,
  );
}

export async function getCreditScoreRefresh() {
  const requestOptions = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(
    buildCreditScoreRefreshEndpoint(),
    requestOptions,
  );
}

export async function getCreditScore3BDial() {
  const requestOptions = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(buildCreditScore3BDial(), requestOptions);
}

export async function putUserAddress(body) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT', body });

  return standardizedFetchHandler(buildUserAddressEndpoint(), requestOptions);
}

export async function patchUser(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PATCH',
    body,
  });

  return standardizedFetchHandler(apiUrls.user(), requestOptions);
}

export async function patchUserWithQueryParams(body, queryParams) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PATCH',
    body,
  });

  return standardizedFetchHandler(apiUrls.user(queryParams), requestOptions);
}

export async function getAffiliatePixels(app) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'POST' });
  const finalApp = !app ? 'CSWEB' : app;

  return standardizedFetchHandler(
    buildAffiliatePixels(finalApp),
    requestOptions,
  );
}

export async function sendDupSSNOTP(otpType) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const mediaType = otpType ? otpType : 'SMS_OTP';

  let url = buildGetDupeOTP(mediaType);

  return standardizedFetchHandler(url, requestOptions);
}

export async function validateDupSSNOTP(requestBody) {
  let marketingAttr = {
    provider: 'TUNE',
    prequalOfferAccept: true,
    currentApp: 'CSWEB',
  };

  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT' });
  const url = buildValidateDupeOTP();

  return new Promise((resolve, reject) => {
    guaranteeTrk(marketingAttr)
      .then(marketingInfo => {
        const body = JSON.stringify(
          Object.assign({}, requestBody, { marketingInfo: marketingInfo }),
        );
        const request = Object.assign({}, requestOptions, { body: body });

        return standardizedFetch(url, request);
      })
      .then(standardResponseHandler)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export async function sendCodeDupSsnKBA() {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: {
      intent: 'DUP_SSN_ACCOUNT_ACTIVATION',
      otpMediaType: 'SMS',
    },
  });

  return standardizedFetchHandler(buildDupKBASendCode(), requestOptions);
}

export const postActivityEventInViewOffers = async productKey => {
  const request = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: {
      eventType: 'VIEWED_OFFER',
      eventData: {
        vertical: 'CREDIT_CARD',
        productKeys: [productKey],
      },
    },
  });

  const url = buildUsersActivityEventInViewOffers();

  return standardizedFetchHandler(url, request);
};

export const putActivityEventTypeInOfferModule = async eventType => {
  const request = buildFetchOptionsWithHeaders({
    method: 'PUT',
  });

  const url = buildUsersActivityEventTypeInOfferModule(eventType);
  return standardizedFetchHandler(url, request);
};

export const getDecreaseCreditCardBalance = async decreaseCreditCardBalance => {
  const request = buildFetchOptionsWithHeaders();

  const url = buildDecreseCreditCardBalance(decreaseCreditCardBalance);
  return standardizedFetchHandler(url, request);
};

export async function verifyPhoneDupSsnKBA(passCode) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: {
      intent: 'DUP_SSN_ACCOUNT_ACTIVATION',
      passcode: passCode,
    },
  });

  return standardizedFetchHandler(
    buildDupKBAPhoneVerification(),
    requestOptions,
  );
}

export async function activateUserDupSsnKBA(jwt2FAToken) {
  const customHeaders = { 'X-cs-2fa': jwt2FAToken };
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'PUT',
      body: {},
    },
    customHeaders,
  );

  return standardizedFetchHandler(
    buildDupKBAPhoneActivateUser(),
    requestOptions,
  );
}

export const userService = {
  get,
  login,
  register,
  update,
  updateUserAddress,
  updateUserPassword,
  updateUserSSN,
  getQuestions,
  submitQuestions,
  updateUserAddressPatch,
};

async function get() {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(apiUrls.user(), requestOptions);
}

async function login(email, password, token, sessionIdentifier, page) {
  const requestBody =
    email && password
      ? { email: email, password: password, apiKey: getSlapiApiKey() }
      : { apiKey: getSlapiApiKey() };

  const trk = getCookieValue('trk');
  const trkHeader = trk ? { 'X-affiliate-tracking-id': trk } : null;

  if (token) {
    Object.assign(requestBody, { token: token });
  }
  if (page) {
    Object.assign(requestBody, { page });
  }
  if (sessionIdentifier) {
    requestBody.sessionIdentifier = sessionIdentifier;
  }

  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
    },
    trkHeader,
  );

  //signup login
  return standardizedFetch(apiUrls.login(), requestOptions).then(response => {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        const errorCode = data && data.code ? data.code : 0;
        return Promise.reject({
          error,
          errorCode,
          responseCode: response.status,
          code: errorCode,
        });
      }
      setCStrack(data.sessionIdentifier);
      // used by rentreporting.creditsesame.com
      setCookieWithExpiration(
        'sessionIdentifier',
        data.sessionIdentifier,
        1,
        null,
        null,
        true,
      );
      return data;
    });
  });
}

async function updateUserAddress(address, validationType) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: address,
  });

  let url = apiUrls.updateUserAddress();

  if (validationType) {
    url = `${url}/?validationType=${validationType}`;
  }

  return standardizedFetchHandler(url, requestOptions);
}

async function updateUserSSN(ssn) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: { ssn: ssn },
  });
  return standardizedFetchHandler(apiUrls.updateUserSSN(), requestOptions);
}

async function getQuestions(tuSessionId, otpFlow) {
  const requestOptions = buildFetchOptionsWithHeaders(null, {
    'X-tu-session-id': tuSessionId,
  });

  let url = apiUrls.questions();
  if (otpFlow) {
    url += `?tuOtp=${otpFlow}`;
  }
  return standardizedFetchHandler(url, requestOptions);
}

export async function guaranteeTrk(marketingInfo) {
  let trkInfo = marketingInfo || {};
  return new Promise((resolve, reject) => {
    const trk = getCookieValue('trk');

    if (trk) {
      trkInfo['affiliateTrackingId'] = trk;
      resolve(trkInfo);
    } else {
      fetchTrkCookies()
        .then(res => {
          const trkAfterRetrieval = getCookieValue('trk');
          trkInfo['affiliateTrackingId'] = trkAfterRetrieval;
          resolve(trkInfo);
        })
        .catch(err => {
          trkInfo['affiliateTrackingId'] = '';
          resolve(trkInfo);
        });
    }
  });
}

async function submitQuestions(requestBody, isDupeFlow) {
  let marketingAttr = {
    provider: 'TUNE',
    prequalOfferAccept: true,
    currentApp: 'CSWEB',
  };

  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT' });
  const url = isDupeFlow ? apiUrls.questionsSSNMatch() : apiUrls.questions();

  return new Promise((resolve, reject) => {
    guaranteeTrk(marketingAttr)
      .then(marketingInfo => {
        let bodyMarketingIrClickId = { ...marketingInfo };
        const irClickId = getMarketingCookieAttr('TransactionID');

        if (irClickId) {
          bodyMarketingIrClickId.partnerTxnId = irClickId;
        }

        const body = JSON.stringify(
          Object.assign({}, requestBody, {
            marketingInfo: bodyMarketingIrClickId,
          }),
        );
        const request = Object.assign({}, requestOptions, { body: body });

        return standardizedFetchHandler(url, request);
      })
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

async function register(user) {
  const affiliateTracking = getMarketingCookies();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: Object.assign({}, user, {
      accountMarketingAttribution: affiliateTracking,
    }),
  });

  const url = user.referrer
    ? `${apiUrls.register()}?referrer=${user.referrer}`
    : apiUrls.register();

  return standardizedFetch(url, requestOptions)
    .then(standardResponseHandler)
    .then(response => {
      return response;
    })
    .catch(err => {
      if (err.status === 400 && err.code === 'SU_1002') {
        //if email exists return success and re-route appropriately in action
        return err;
      }
      return Promise.reject(err);
    });
}

async function update(user) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: JSON.stringify(user),
  });

  return standardizedFetchHandler(apiUrls.user(), requestOptions);
}

async function updateUserPassword(userDetails) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: userDetails,
  });

  return standardizedFetchHandler(apiUrls.updateUserPassword(), requestOptions);
}

export async function updateUserAddressPatch(address, validationType) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PATCH',
    body: JSON.stringify(address),
  });

  let url = apiUrls.updateUserAddress();

  if (validationType) {
    url = `${url}/?validationType=${validationType}`;
  }
  return standardizedFetchHandler(url, requestOptions);
}

export const deactivateUser = async body => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'DELETE',
    body,
  });
  return standardizedFetchHandler(apiUrls.user(), requestOptions);
};

export async function getReferrerName(referrerCode) {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    buildInvitationReferrerEndpoint(referrerCode),
    requestOptions,
  );
}

export async function updateUserCredentials(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });

  return standardizedFetchHandler(
    buildUpdateCredentialsEndpoint(),
    requestOptions,
  );
}

export async function validateEmailChange(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(buildValidateEmailChange(), requestOptions);
}

export async function verifyPhoneForEmailChange(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(
    buildVerifyPhoneEmailChange(),
    requestOptions,
  );
}

export async function sendCodeForEmailChange(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(
    buildSendCodeEmailChange('RESET_CREDENTIALS'),
    requestOptions,
  );
}

export async function resetCredentialsForEmailChange(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });

  return standardizedFetchHandler(
    buildResetCredentialsEmailChange(),
    requestOptions,
  );
}

export async function verifyEmailChange(body) {
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: { ...body, blackbox },
  });

  return standardizedFetchHandler(buildVerifyEmailChange(), requestOptions);
}

export async function updateEmailOTP(body, otpOption) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });
  const params = convertObjectToQueryString({ otpOption });
  return standardizedFetchHandler(
    buildSelfServeChangeEmail() + params,
    requestOptions,
  );
}

export async function verifyPhoneChange(body) {
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: { ...body, blackbox },
  });

  return standardizedFetchHandler(buildVerifyPhoneChange(), requestOptions);
}

export async function updatePhoneOTP(body, otpOption) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });
  const params = convertObjectToQueryString({ otpOption });
  return standardizedFetchHandler(
    buildSelfServeChangePhone() + params,
    requestOptions,
  );
}

export async function verifyUserSSN(body) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT', body });

  return standardizedFetchHandler(buildUserVerifySSNEndpoint(), requestOptions);
}

export async function verifyUserId(body) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'POST', body });

  return standardizedFetchHandler(
    buildUserIdVerificationEndpoint(),
    requestOptions,
  );
}

export async function updateUserIdVerificationSubmitted(body) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT', body });

  return standardizedFetchHandler(
    buildUserIdVerificationSubmittedEndpoint(),
    requestOptions,
  );
}

export async function getUserDetailsDM(promoCode, trkId) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const query = convertObjectToQueryString({ promoCode, trkId });
  return standardizedFetchHandler(
    buildUserDetailsDMEndpoint() + query,
    requestOptions,
  );
}

export async function getCoupons() {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(buildCoupons(), requestOptions);
}

export async function getAcknowledgePrescreen() {
  const requestOptions = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(buildAcknowledgePrescreen(), requestOptions);
}
