const USER_LOGOUT_TIME = 'USER_LOGOUT_TIME';
const OCF_LOGOUT_TIME = 'OCF_LOGOUT_TIME';
const API_KEY_LOGOUT_TIME = 'API_KEY_LOGOUT_TIME';
const TRANSFER_METADATA = 'TRANSFER_METADATA';
const NOTIFICATION_PREFIX = 'NOTIFICATION-';
const LAST_SEEN_CREDIT_REPORT = 'LAST_SEEN_CREDIT_REPORT';
const REMEMBER_ME = 'REMEMBER_ME';
const FIRST_NAME_USER = 'FIRST_NAME_USER';
const PLAID_OAUTH_LINK_TOKEN = 'PLAID_OAUTH_LINK_TOKEN';
// for personal loan marketplace
const DEFAULT_PERSONAL_LOAN_INTENT = 'DEFAULT_PERSONAL_LOAN_INTENT';
const DEFAULT_PERSONAL_LOAN_AMOUNT = 'DEFAULT_PERSONAL_LOAN_AMOUNT';
const TWO_FACTOR_VERIFIED_SESSION_IDENTIFIER =
  'TWO_FACTOR_VERIFIED_SESSION_IDENTIFIER';
const OPTIMIZELY_UUID = 'OPTIMIZELY_UUID';
const SESSION_COUNT = 'SESSION_COUNT';
const SESSION_COUNT_CASH_ENHANCED = 'SESSION_COUNT_CASH_ENHANCED';
export const CASH_ADDRESS_UPDATE_METADATA = 'CASH_ADDRESS_UPDATE_METADATA';

export const PREMIUM_SCORE_AUTO_UPDATE_MODAL = 'cs.autoUpdateModal';
export const USER_QUESTIONAIRE_MODAL_DISPLAYED = 'cs.userGoalsModalDisplayed';
export const PREMIUM_PAYMENT_MODAL_DISPLAYED =
  'cs.premiumPaymentPendingModalDisplayed';

const AUTO_LOAN_REFI = 'AUTO_LOAN_REFI';
const HIDE_CC_COMPARISON_ON_CATEGORIES = 'HIDE_CC_COMPARISON_ON_CATEGORIES';
export const DATA_CACHE_CC_COMPARISON = 'DATA_CACHE_CC_COMPARISON';

export const OCF_LOGIN_REDIRECT = 'OCF_LOGIN_REDIRECT';

export const USER_COMES_FROM_CREDIT_MATCHING =
  'USER_COMES_FROM_CREDIT_MATCHING';

export const BRAZE_CONTENT_CARDS_SHOWN = 'BRAZE_CONTENT_CARDS_SHOWN';

export const LOGIN_COUNT_SUGGESTED_CARD_BANNER =
  'LOGIN_COUNT_SUGGESTED_CARD_BANNER';
export const LOGIN_COUNT_COLLECTION_GUIDE = 'LOGIN_COUNT_COLLECTION_GUIDE';
export const CB_2_MODULE_SHOW_PAYMENT_SECTION =
  'CB_2_MODULE_SHOW_PAYMENT_SECTION';

export const HIDE_UPDATE_ADDRESS_MODULE = 'HIDE_UPDATE_ADDRESS_MODULE';

export const FIRST_SEEN_PLUS_INTERSTITIAL = 'FIRST_SEEN_PLUS_INTERSTITIAL';

window.localStorageFallbackMemoryMap = {};

export function setItem(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    let finalVal = value;
    if (typeof finalVal !== 'string') {
      finalVal = String(value);
    }
    window.localStorageFallbackMemoryMap[key] = finalVal;
    console.log('Unable to setItem', key, e);
  }
}

export function getItem(key) {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    console.log('Unable to getItem', key, e);
    return window.localStorageFallbackMemoryMap[key];
  }
}

export function removeItem(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    window.localStorageFallbackMemoryMap[key] = undefined;
    console.log('Unable to removeItem', key, e);
  }
}

export function setUserLogoutTime(t) {
  setItem(USER_LOGOUT_TIME, t);
}

export function getUserLogoutTime() {
  return getItem(USER_LOGOUT_TIME);
}

export function clearLogoutTime() {
  removeItem(USER_LOGOUT_TIME);
}

export function setOcfLogoutTime(t) {
  setItem(OCF_LOGOUT_TIME, t);
}

export function getOcfLogoutTime() {
  return getItem(OCF_LOGOUT_TIME);
}

export function setApiKeyLogoutTime(t) {
  setItem(API_KEY_LOGOUT_TIME, t);
}

export function getApiKeyLogoutTime(t) {
  return getItem(API_KEY_LOGOUT_TIME);
}

export function setTransferData(key, value) {
  return setItem(`${TRANSFER_METADATA}_${key}`, value);
}

export function getTransferData(key) {
  return getItem(`${TRANSFER_METADATA}_${key}`);
}

export function getDefaultPersonalLoanIntent() {
  return getItem(DEFAULT_PERSONAL_LOAN_INTENT);
}

export function setDefaultPersonalLoanIntent(intent) {
  setItem(DEFAULT_PERSONAL_LOAN_INTENT, intent);
}

export function getDefaultPersonalLoanAmount() {
  return getItem(DEFAULT_PERSONAL_LOAN_AMOUNT);
}

export function setDefaultPersonalLoanAmount(amount) {
  setItem(DEFAULT_PERSONAL_LOAN_AMOUNT, amount);
}

export function setCStrack(trkId) {
  setItem('csSessionId', trkId);
}

export function getCStrack() {
  const val = getItem('csSessionId');
  return val;
}

export function clearCStrack() {
  removeItem('csSessionId');
}

export function setCSPublicTrack(trackId) {
  setItem('csPublicSessionId', trackId);
}

export function getCSPublicTrack() {
  const val = getItem('csPublicSessionId');
  return val;
}

export function clearCSPublicTrack() {
  removeItem('csPublicSessionId');
}

export function setRefCode(refCode) {
  setItem('refCode', refCode);
}

export function getRefCode() {
  return getItem('refCode');
}

export function clearRefCode() {
  removeItem('refCode');
}

export function setReferredBy(referredBy) {
  setItem('referredBy', referredBy);
}

export function getReferredBy() {
  return getItem('referredBy');
}

export function clearReferredBy() {
  removeItem('referredBy');
}

export function setLoginFromLogoutData(data) {
  setItem('loginFromLogoutData', data);
}

export function getLoginFromLogoutData() {
  return getItem('loginFromLogoutData');
}

export function setUsrTkn(usrToken) {
  setItem('userToken', usrToken);
}

export function getUsrTkn() {
  return getItem('userToken');
}

export function setResurrectedUser(data) {
  setItem('isResurrectedUser', data);
}

export function getResurrectedUser() {
  return getItem('isResurrectedUser');
}

export function setHideAutomaticUpdateModalExperience() {
  setItem('hideAutomaticUpdateModal', true);
}

export function getHideAutomaticModalExperience() {
  return getItem('hideAutomaticUpdateModal');
}

export function setTerminateAccountAttemptCount(attemptCount) {
  let dateTime = new Date();
  dateTime.setHours(dateTime.getHours() + 2);
  setItem(
    'terminateAccountAttempt',
    JSON.stringify({ attemptCount: attemptCount, dateTime: dateTime }),
  );
}

export function clearTerminateAccountAttemptCount() {
  removeItem('terminateAccountAttempt');
}

export function clearUsrTkn() {
  removeItem('userToken');
}

export function clearAcknowledgePrescreen() {
  removeItem('acknowledge-prescreen');
}

export function clearResurrectedUser() {
  removeItem('isResurrectedUser');
}

export function setNotificationSeen(name) {
  const fullKey = NOTIFICATION_PREFIX + name;
  setItem(fullKey, 'true');
}

export function isNotificationSeen(name) {
  const fullKey = NOTIFICATION_PREFIX + name;
  const inStorage = getItem(fullKey);

  return !(inStorage === null || inStorage === undefined);
}

export function getLastSeenCreditReport() {
  return getItem(LAST_SEEN_CREDIT_REPORT);
}

export function setLastSeenCreditReport(timestamp) {
  setItem(LAST_SEEN_CREDIT_REPORT, timestamp);
}

export function isLoggingToWindow() {
  const val = getItem('LOG_TRACKING_ON_WINDOW');
  return val !== undefined;
}

export function getLoggingFilters() {
  const val = getItem('LOGGING_FILTERS');
  return val;
}
export function setLoggingFilters(loggingFilterString) {
  setItem('LOGGING_FILTERS', loggingFilterString);
}

export function testLocalStorage() {
  try {
    const test = 'TEST_LOCAL';
    localStorage.setItem(test, test);
    if (localStorage.getItem(test) !== test)
      return 'Local storage is not persisting values properly';
    localStorage.removeItem(test);
    return null;
  } catch (e) {
    return false;
  }
}

export function setLastUserTokenToLogIn(userToken) {
  setItem('LAST_USER_TOKEN_TO_LOG_IN', userToken);
}
export function getLastUserTokenToLogIn() {
  return getItem('LAST_USER_TOKEN_TO_LOG_IN');
}

export function setRememberMe(email) {
  setItem(REMEMBER_ME, email);
}

export function getRemeberMe() {
  return getItem(REMEMBER_ME);
}

export function setFirstNameUser(email) {
  setItem(FIRST_NAME_USER, email);
}

export function getFirstNameUser() {
  return getItem(FIRST_NAME_USER);
}

export const setAcknowledgePrescreen = isAcknowledgePrescreen => {
  setItem('acknowledge-prescreen', isAcknowledgePrescreen);
};

export const getAcknowledgePrescreen = () => {
  return getItem('acknowledge-prescreen');
};

export function setLastInsuranceInteractionTimestamp(timestamp) {
  setItem('LAST_INSURANCE_INTERACTION_TIMESTAMP', timestamp);
}

export function getLastInsuranceInteractionTimestamp() {
  return getItem('LAST_INSURANCE_INTERACTION_TIMESTAMP');
}

export function setPlaidOauthLinkToken(plaidLinkToken) {
  setItem(PLAID_OAUTH_LINK_TOKEN, plaidLinkToken);
}

export function getPlaidOauthLinkToken() {
  return getItem(PLAID_OAUTH_LINK_TOKEN);
}

export function set2FAVerifiedSessionIdentifier(sessionIdentifier) {
  setItem(TWO_FACTOR_VERIFIED_SESSION_IDENTIFIER, sessionIdentifier);
}
export function get2FAVerifiedSessionIdentifier() {
  return getItem(TWO_FACTOR_VERIFIED_SESSION_IDENTIFIER);
}

export function clear2FAVerifiedSessionIdentifier() {
  removeItem(TWO_FACTOR_VERIFIED_SESSION_IDENTIFIER);
}

export function setOfferTilesOrder(offerTilesOrder) {
  setItem('OFFER_TILES_ORDER', offerTilesOrder);
}

export function getVriRandom() {
  return getItem('VRI_RANDOM');
}

export function setVriRandom(offerTilesOrder) {
  setItem('VRI_RANDOM', offerTilesOrder);
}

export function setSnackBar(showSnackBarValue) {
  setItem('showSnackBar', showSnackBarValue);
}

export function getSnackBar() {
  return getItem('showSnackBar');
}

export function clearSnackBar() {
  removeItem('showSnackBar');
}

export function getOptimizelyUUID() {
  return getItem(OPTIMIZELY_UUID);
}

export function setOptimizelyUUID(value) {
  setItem(OPTIMIZELY_UUID, value);
}

export function setSessionCount(value) {
  setItem(SESSION_COUNT, value);
}

export function getSessionCount() {
  return getItem(SESSION_COUNT);
}

export function setSessionCountCashEnhanced(value) {
  setItem(SESSION_COUNT_CASH_ENHANCED, value);
}

export function getSessionCountCashEnhanced() {
  return getItem(SESSION_COUNT_CASH_ENHANCED);
}

export function setSelectedAutoLoanRefi(autoLoanRefi) {
  setItem(AUTO_LOAN_REFI, autoLoanRefi);
}

export function getSelectedAutoLoanRefi() {
  return getItem(AUTO_LOAN_REFI);
}

export function setCcComparisonOnCategories(isCcComparison) {
  setItem(HIDE_CC_COMPARISON_ON_CATEGORIES, isCcComparison);
}

export function getCcComparisonOnCategories() {
  return getItem(HIDE_CC_COMPARISON_ON_CATEGORIES);
}

export function setDataCacheCcComparison(cardsSelected) {
  setItem(DATA_CACHE_CC_COMPARISON, JSON.stringify(cardsSelected));
}

export function getDataCacheCcComparison() {
  return getItem(DATA_CACHE_CC_COMPARISON);
}

export function setOcfLogInRedirect(ocfPathname) {
  setItem(OCF_LOGIN_REDIRECT, ocfPathname);
}

export function getOcfLogInRedirect() {
  return getItem(OCF_LOGIN_REDIRECT);
}

export const setIsDmCampaign = () => {
  setItem('isDMCampaign', true);
};

export const getIsDmCampaign = () => {
  return getItem('isDMCampaign');
};

export const setIsTimeToRecertifyIncomeUpdated = recertify => {
  setItem('isTimeToRecertifyIncomeUpdated', recertify);
};

export const getIsTimeToRecertifyIncomeUpdated = () => {
  return getItem('isTimeToRecertifyIncomeUpdated');
};

export const getHavePreApprovedOffers = () => {
  return getItem('havePreApprovedOffers');
};

export const getDailyScoreRefreshCount = () => {
  return getItem('dailyScoreRefreshCount');
};

export const setDailyScoreRefreshCount = count => {
  setItem('dailyScoreRefreshCount', count);
};

export const getHideFreeTrialPremiumBanner = () => {
  return getItem('hideFreeTrialPremiumBanner');
};

export const setHideFreeTrialPremiumBanner = hide => {
  setItem('hideFreeTrialPremiumBanner', hide);
};

export const clearHideFreeTrialPremiumBanner = () => {
  removeItem('hideFreeTrialPremiumBanner');
};

export function getCb2ModuleShowPaymentSection() {
  return getItem(CB_2_MODULE_SHOW_PAYMENT_SECTION);
}

export function setCb2ModuleShowPaymentSection(status) {
  setItem(CB_2_MODULE_SHOW_PAYMENT_SECTION, status);
}

export const getHideUpdateAddressModule = () => {
  return getItem(HIDE_UPDATE_ADDRESS_MODULE);
};

export function setHideUpdateAddressModule(status) {
  setItem(HIDE_UPDATE_ADDRESS_MODULE, status);
}

export const clearCreditManagerStorage = () => {
  removeItem('SHOW_CALENDAR_WELCOME_MODAL');
  removeItem('SHOW_CONFFETI');
};
