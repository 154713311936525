/* eslint-disable */
import {
  getSlapiUrl,
  getSlapiApiKey,
  getMixpanelVisitorToken,
  DOMAIN,
} from 'helpers/env';

window.CSMxpTracker = (function (w, d, config) {
  'use strict';
  var EVENT_NAME_PAGE_VIEW = 'View Page',
    EVENT_NAME_CLICK = 'Click',
    SITE_PUBLIC = 'Public',
    SITE_SECURE = 'Secure',
    SITE_MORPHEUS = 'Morpheus',
    WEBSITE_NAME_CSWEB = 'CSWEB',
    WEBSITE_NAME_WORDPRESS = 'Wordpress',
    WEBSITE_NAME_MORPHEUS = 'Morpheus',
    WEBSITE_NAME_CS_CASH = 'SesameCash',
    TRAFFIC_TYPE_DIRECT = 'direct',
    TRAFFIC_TYPE_ORGANIC = 'organic',
    TRAFFIC_TYPE_SOCIAL = 'social',
    TRAFFIC_TYPE_REFERRAL = 'referer',
    ENV_PROD = 'prod',
    ENV_DEV = 'dev',
    lp_url = '',
    ckv_initial_referrer = '',
    trk = (function (w, d, c, h) {
      var trkData = {},
        pageName = '',
        optTrackingData = null;

      function getPageName() {
        return pageName !== '' ? pageName : h.parsePageName();
      }

      /**
       * Set Source using "inbound tracking Params" cookie if set
       * This method assumes all tracking params including "src" and "subsrc"
       * are available in this one cookie
       * @returns {Boolean}
       */
      function setSourceFromCookie() {
        var cData,
          i,
          ckv = h.cookie.get(c.cookie.inboundTrkParams);

        if (typeof ckv === 'string') {
          try {
            cData = JSON.parse(ckv);
            for (i in cData) {
              if (
                cData.hasOwnProperty(i) &&
                typeof c.eventProperties.mkt[i] !== 'undefined'
              ) {
                trkData[c.eventProperties.mkt[i]] = cData[i];
              }
            }
            return true;
          } catch (e) {
            h.log(e);
          }
        }

        return false;
      }

      /**
       * Determine the Source and Medium of incoming traffic. Set a cookie
       * to be used for subsequent page views
       * @param {string} refUrl
       * @param {string} host
       */
      function determineSource(refUrl, host) {
        var src = '',
          chnl = '',
          usrCk = {},
          tL = '';

        if (refUrl !== '') {
          tL = d.createElement('a');
          tL.href = refUrl;
          if (tL.hostname !== host) {
            if (h.isSearchEngine(tL.hostname)) {
              chnl = TRAFFIC_TYPE_ORGANIC;
            } else if (h.isSocialMedia(tL.hostname)) {
              chnl = TRAFFIC_TYPE_SOCIAL;
            } else {
              chnl = TRAFFIC_TYPE_REFERRAL;
            }

            src = tL.hostname;
          } else {
            chnl = TRAFFIC_TYPE_DIRECT;
            src = TRAFFIC_TYPE_DIRECT;
          }
        } else {
          chnl = TRAFFIC_TYPE_DIRECT;
          src = TRAFFIC_TYPE_DIRECT;
        }

        usrCk[c.eventProperties.mkt.MarketingSource] = trkData[
          c.eventProperties.mkt.MarketingSource
        ] = src;
        usrCk[c.eventProperties.mkt.MarketingMedium] = trkData[
          c.eventProperties.mkt.MarketingMedium
        ] = chnl;
        h.setTrkCookie(usrCk);
      }

      /**
       * Set VistorId, user token and JSession id if available
       */
      function setTrkIds() {
        var vid = h.cookie.get(c.cookie.vid),
          jSessId = h.cookie.get(c.cookie.jSessId),
          eProp = c.eventProperties;

        trkData[eProp.visitorSessionId] = vid;

        if (typeof jSessId === 'string') {
          trkData[eProp.jSessionId] = jSessId;
        }
      }

      function createCookie(key, value) {
        const ck_key = key;
        const ck_value = value;

        d.cookie = [
          encodeURIComponent(ck_key),
          '=',
          encodeURIComponent(ck_value),
          `; domain=${DOMAIN}`,
          '; path=/',
        ].join('');
      }

      function isTest() {
        var pattern = new RegExp(
          `(local|testblog2|test([0-9]{0,2}\\.hq)?)\\.${DOMAIN}`,
        );
        // var pattern =
        //   /(local|testblog2|test([0-9]{0,2}\.hq)?)\.creditsesame.com/;
        return pattern.test(window.location.hostname);
      }

      function showEventConsole(eN, eD) {
        if (isTest()) {
          console.log('Tracking event: ', eN, eD);
        }
      }

      function getPageFormFactor() {
        var width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        if (width < 640) {
          return 'Public Mobile Web';
        } else {
          return 'Public Web';
        }
      }

      function getCookiePropertyValue(cookieName, propertyName) {
        var cookieValue = h.cookie.get(cookieName);
        if (typeof cookieValue === 'string') {
          try {
            cookieValue = JSON.parse(cookieValue);
            if (typeof cookieValue[propertyName] !== 'undefined') {
              return cookieValue[propertyName];
            }
          } catch (e) {
            h.log(e);
          }
        }
        return null;
      }

      /**
       * Prepares a payload for mixpanel event
       */
      function prepEventData() {
        var host = w.location.hostname,
          url = w.location.href,
          refUrl =
            getCookiePropertyValue(
              c.cookie.inboundTrkParams,
              'initial_referrer_url',
            ) || d.referrer,
          eProp = c.eventProperties,
          comesFromInboundCookie = !!getCookiePropertyValue(
            c.cookie.inboundTrkParams,
            'initial_referrer_url',
          );

        trkData = {};
        trkData[eProp.pageUrl] = url;
        trkData[eProp.referrer] = refUrl;
        trkData[eProp.site] = h.getSiteType();
        trkData[eProp.firstTimeVisit] = h.isFirstTimeVisit();
        trkData[eProp.PageFormFactor] = getPageFormFactor();

        setTrkIds();
        buildOptmizelyXTrackingData();
        loadLandingPageURL(url);

        if (!setSourceFromCookie()) {
          determineSource(refUrl, host);
        }
        loadInitialReferrer(refUrl, host, comesFromInboundCookie);
      }

      function getTrkData() {
        var o = Object.create(trkData);
        return o;
      }

      /**
       * On very first visit of the session, clear any UTM super properties set by
       * mixpanel.
       * The super properties of the current session if any should be set after previous
       * super properties are cleared.
       *
       * Can't use mixpanel.reset as it will reset the Distinc Id (Visitor ID) of the vsitor
       *
       */
      function clearUtmSuperProperties() {
        if (isFirstSessionVisit()) {
          var cks =
              'utm_source utm_medium utm_campaign utm_content utm_term $search_engine mp_keyword'.split(
                ' ',
              ),
            i = 0;
          for (i; i < cks.length; i++) {
            mixpanel.unregister(cks[i]);
          }
        }
      }

      function isFirstSessionVisit() {
        var cval = h.cookie.get(c.cookie.sessionFirstVisit);
        if (typeof cval !== 'string') {
          h.cookie.set(c.cookie.sessionFirstVisit, '1');
          return true;
        }

        return false;
      }

      function getClientIpFromCache() {
        var ip = h.cookie.get(c.cookie.clientIP);
        return ip && ip != null ? ip : false;
      }

      function isValidIpTime(time) {
        return Date.now() - time < 1800000 ? true : false;
      }

      function getClientIP(callback) {
        if (getClientIpFromCache()) {
          callback(getClientIpFromCache());
        } else {
          var hostname = getSlapiUrl();
          // h.getEnv() === ENV_DEV
          //   ? 'https://preprodapi.creditsesame.com/api/v2'
          //   : 'https://api.creditsesame.com/api/v2';

          var xhr = new XMLHttpRequest();
          xhr.open('POST', hostname + '/sessions');
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
          xhr.setRequestHeader('X-cs-platform', 'cs_public_site');
          xhr.setRequestHeader('Accept', 'application/json');
          xhr.onload = function () {
            var ip = null;
            if (xhr.status === 201) {
              try {
                var data = JSON.parse(xhr.responseText);
              } catch (exp) {
                var data = {};
              }
              if (typeof data.clientIp !== 'undefined') {
                ip = data.clientIp;
                setClientIP(ip);
              }
            }
            callback(ip);
          };
          xhr.onerror = function () {
            callback(null);
          };
          xhr.ontimeout = function () {
            callback(null);
          };

          var apiKey = h.getSlapiKey();
          try {
            var data = JSON.stringify({ apiKey: apiKey });
            xhr.send(data);
          } catch (e) {
            callback(null);
          }
        }
      }

      function setClientIP(ip) {
        h.cookie.set(c.cookie.clientIP, ip, false, 30);
      }
      function loadInitialReferrer(
        inRef,
        lhost,
        comesFromInboundCookie = false,
      ) {
        var ref = inRef,
          inboundCookie,
          host = getCookiePropertyValue(
            c.cookie.inboundTrkParams,
            'initial_referrer_domain',
          );

        if (ref && !comesFromInboundCookie && !host)
          host = ref.match(/:\/\/(.[^/]+)/)[1];

        if (!ref || host === lhost) {
          ref = TRAFFIC_TYPE_DIRECT;
          host = TRAFFIC_TYPE_DIRECT;
        }
        if (!comesFromInboundCookie) {
          inboundCookie = h.cookie.get(c.cookie.inboundTrkParams);
          if (typeof inboundCookie === 'string') {
            inboundCookie = JSON.parse(inboundCookie);
            const cookieValueObj = {
              ...inboundCookie,
              initial_referrer_url: ref,
              initial_referrer_url_domain: host,
            };

            h.setTrkCookie(cookieValueObj);
          } else {
            h.setTrkCookie({
              initial_referrer_url: ref,
              initial_referrer_url_domain: host,
            });
          }
        }
        trkData[c.eventProperties.initialReferrerUrl] = ref;
        trkData[c.eventProperties.initialReferrerUrlDomain] = host;
      }

      // ** Landing Page URL Track Data*/
      function loadLandingPageURL(inUrl) {
        var url = inUrl.split('?')[0],
          cookie_name = c.cookie.landingPageUrl;

        if (!h.getLandingPageUrl()) {
          createCookie(cookie_name, url);
          lp_url = h.getLandingPageUrl();
        } else {
          lp_url = h.getLandingPageUrl();
        }

        trkData[c.eventProperties.landingPageUrl] = lp_url;
      }
      //**End Landing Page URL  */

      /** Start Helper functions, add Optimizely data to mixpanel tracking **/
      function getOptimizelyTrackingInfo() {
        var optTrkAttrs = null;

        if (window.optimizely && typeof window.optimizely.get === 'function') {
          optTrkAttrs = [];
          var activeExperiments = window.optimizely
            .get('state')
            .getExperimentStates({
              isActive: true,
            });

          if (activeExperiments) {
            for (var key in activeExperiments) {
              if (
                activeExperiments.hasOwnProperty(key) &&
                !activeExperiments[key].isInExperimentHoldback
              ) {
                optTrkAttrs.push({
                  'Experiment Name': activeExperiments[key].experimentName,
                  'Experiment Id': key,
                  'Variation Name': activeExperiments[key].variation.name,
                  'Variation Id': activeExperiments[key].variation.id,
                });
              }
            }
          }
        }

        return optTrkAttrs;
      }
      function buildOptmizelyXTrackingData() {
        if (!optTrackingData) {
          optTrackingData = getOptimizelyTrackingInfo();
        }

        trkData['Optimizely Experiments'] = optTrackingData;
      }
      /** END - helper function, Optimizely data to mixpanel tracking **/

      /**
       * Reports event to Mixpanel.
       * Check if Mixpanel Object is available, else sets a time out to
       * retry after 150ms.
       * @param {string} eN Event Name
       * @param {object} eD Event properties object
       * @param {function} callback Callback function
       */
      function isTest() {
        var pattern = new RegExp(
          `(local|testblog2|test([0-9]{0,2}\\.hq)?)\\.${DOMAIN}`,
        );
        // var pattern =
        //   /(local|testblog2|test([0-9]{0,2}\.hq)?)\.creditsesame.com/;
        return pattern.test(window.location.hostname);
      }

      function showEventConsole(eN, eD) {
        if (isTest()) {
          console.log('Tracking event: ' + eN, eD);
        }
      }

      function isMixpanelLoaded() {
        return (
          typeof w.mixpanel?.get_distinct_id === 'function' &&
          typeof w.mixpanel?.unified_public_site?.get_distinct_id === 'function'
        );
      }

      function reportEvent(eN, eD, callback, isUnifiedBucket = false) {
        if (typeof w.mixpanel !== 'undefined') {
          getClientIP(function (ip) {
            if (ip) {
              eD[c.eventProperties.clientIP] = ip;
            }
            let mixpanelError = null;
            try {
              if (isUnifiedBucket) {
                w.mixpanel.unified_public_site.track(eN, eD, callback);
              } else {
                w.mixpanel.track(eN, eD, callback);
              }
            } catch (e) {
              mixpanelError = true;
            }

            if (
              (!isMixpanelLoaded() || mixpanelError) &&
              typeof callback === 'function'
            ) {
              callback();
            }
            showEventConsole(eN, eD);
          });
        } else {
          setTimeout(function () {
            reportEvent(eN, eD);
          }, 100);
        }
      }

      function reportFormSubmitEvent(elem, eN, eD) {
        if (typeof w.mixpanel !== 'undefined') {
          getClientIP(function (ip) {
            if (ip) {
              eD[c.eventProperties.clientIP] = ip;
            }
            w.mixpanel.track_forms(elem, eN, eD);
          });
        } else {
          setTimeout(function () {
            reportFormSubmitEvent(elem, eN, eD);
          }, 100);
        }
      }

      function reportLinkClickEvent(elem, eN, eD) {
        if (typeof w.mixpanel !== 'undefined') {
          getClientIP(function (ip) {
            if (ip) {
              eD[c.eventProperties.clientIP] = ip;
            }
            w.mixpanel.track_links(elem, eN, eD);
          });
        } else {
          setTimeout(function () {
            reportLinkClickEvent(elem, eN, eD);
          }, 100);
        }
      }

      h.pollyfillIsArray();

      clearUtmSuperProperties();
      prepEventData();

      return {
        setPageName: function (pg) {
          pageName = pg;
          return this;
        },
        trackPageView: function (callback) {
          var eventName = 'View Page',
            dt = getTrkData();
          dt[c.eventProperties.pageName] = getPageName();
          reportEvent(eventName, dt);
          reportEvent(eventName, dt, callback, true);
          return this;
        },
        trackEvent: function (type, data, callback) {
          var dt = getTrkData();
          dt[c.eventProperties.pageName] = getPageName();
          for (var i in data) {
            dt[i] = data[i];
          }

          reportEvent(type, dt, callback);
        },
        trackClick: function (type, data, callback) {
          var eventName = 'Click',
            cprops = c.eventProperties.click,
            dt = getTrkData();
          dt[c.eventProperties.pageName] = getPageName();
          dt[cprops.ModuleName] = data[cprops.ModuleName];
          for (var i in data) {
            if (typeof cprops[i] !== 'undefined') {
              dt[i] = data[i];
            }
          }
          reportEvent(eventName, dt, callback);
          return this;
        },
        trackLinkClick: function (elem, type, data) {
          var eventName = 'Click',
            cprops = c.eventProperties.click,
            dt = getTrkData();
          dt[c.eventProperties.pageName] = getPageName();
          for (var i in data) {
            if (typeof cprops[i] !== 'undefined') {
              dt[i] = data[i];
            }
          }
          reportLinkClickEvent(elem, type, dt);
        },
        trackFormSubmit: function (elem, type, data) {
          var eventName = 'Click',
            dt = getTrkData();
          dt[c.eventProperties.pageName] = getPageName();
          dt[cprops.ModuleName] = data[cprops.ModuleName];
          for (var i in data) {
            dt[i] = data[i];
          }

          reportFormSubmitEvent(elem, eventName, dt);
        },
        getClickProps: function () {
          return c.eventProperties.click;
        },
        getSeenModuleProps: function () {
          return c.eventProperties.seenModule;
        },
      };
    })(
      w,
      d,
      config,
      (function (w, d, c) {
        var host = w.location.hostname,
          path = w.location.pathname;

        return {
          isSearchEngine: function (url) {
            var regex =
              /\.(google|bing|yahoo|aol|duckduckgo|baidu|ask|excite)\.com$/;
            return regex.test(url);
          },
          isSocialMedia: function (url) {
            var regex =
              /\.?(facebook|twitter|pinterest|snapchat|tiktok|linkedin|instagram|quora|stumbleupon|digg|reddit|tumbler|plus\.google)\.com$/;
            return regex.test(url);
          },
          pollyfillIsArray: function () {
            if (!Array.isArray) {
              Array.isArray = function (arg) {
                return Object.prototype.toString.call(arg) === '[object Array]';
              };
            }
          },
          isFirstTimeVisit: function () {
            var mxpId = this.getMxpId(),
              mxpCkName = 'mp_' + mxpId + '_mixpanel',
              mxpCk = this.cookie.get(mxpCkName);

            if (typeof mxpCk === 'string') {
              return typeof this.cookie.get(c.cookie.newUsr) === 'string'
                ? true
                : false;
            }

            this.cookie.set(c.cookie.newUsr, '1');
            return true;
          },
          getLandingPageUrl: function () {
            let decodedCookie = decodeURIComponent(d.cookie);
            let ca = decodedCookie.split(';');
            let cookieValue;
            for (let i = 0; i < ca.length; i++) {
              const cookie = ca[i].split('=');
              const cookieKey = cookie && cookie[0];
              if (cookieKey.trim() === c.cookie.landingPageUrl) {
                cookieValue = cookie[1];
                break;
              }
            }
            return cookieValue;
          },
          getInitialReferrerUrl: function () {
            let decodedCookie = decodeURIComponent(d.cookie);
            let ca = decodedCookie.split(';');
            let cookieValue;
            for (let i = 0; i < ca.length; i++) {
              const cookie = ca[i].split('=');
              const cookieKey = cookie && cookie[0];
              if (cookieKey.trim() === c.cookie.initialReferrerUrl) {
                cookieValue = cookie[1];
                break;
              }
            }
            return cookieValue;
          },
          getSiteType: function () {
            if (
              this.getWebsiteName() === WEBSITE_NAME_WORDPRESS ||
              this.getWebsiteName() === WEBSITE_NAME_CS_CASH
            ) {
              return SITE_PUBLIC;
            } else if (this.getWebsiteName() === WEBSITE_NAME_MORPHEUS) {
              return SITE_MORPHEUS;
            }
            var regex = /^(\/credit-cards\/|\/personal-loans\/)\.*/;
            return regex.test(path) ? SITE_PUBLIC : SITE_SECURE;
          },
          getWebsiteName: function () {
            var regex =
              /(^(testblog2|www|local)\.creditsesame\.com|creditsesame\.local|[a-z]+\.csesa\.me)/;
            var regexMorpheus =
              /^(secured|morpheus|preprod-morpheus)\.creditsesame\.com/;
            var regexSesameCash =
              /^((staging|www|test|local)\.)?sesamecash\.com/;
            if (regex.test(host)) {
              return WEBSITE_NAME_WORDPRESS;
            } else if (regexMorpheus.test(host)) {
              return WEBSITE_NAME_MORPHEUS;
            } else if (regexSesameCash.test(host)) {
              return WEBSITE_NAME_CS_CASH;
            } else {
              return WEBSITE_NAME_CSWEB;
            }
          },
          parsePageName: function () {
            path = path.trim();
            var pageNameMarketing =
              document.getElementById('marketingPageName');
            if (path === '/' || path === '') {
              return 'Home Page';
            } else if (pageNameMarketing) {
              return pageNameMarketing.getAttribute('data-mkt-page-name');
            } else {
              var pageName = '',
                pathParts = path.split('/');

              if (pathParts.length > 0) {
                pageName = pathParts.reduce(function (a, b) {
                  b = b.trim();
                  if (b !== '') {
                    a = a + ' ' + b.replace(/\-/g, ' ').toUpperCase();
                  }
                  return a;
                }, '');
              } else {
                pageName = 'Page Name Error';
              }

              return pageName.replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });
            }
          },
          getEnv: function () {
            var regex =
              /^(dev|test|local|preprod|staging)([a-z0-9.-]{1,})?\.creditsesame\.com(:\d+)?$/;
            return regex.test(host) || host == 'staging.sesamecash.com'
              ? ENV_DEV
              : ENV_PROD;
          },
          getSlapiKey: function () {
            return getSlapiApiKey();
          },
          getMxpId: function () {
            return getMixpanelVisitorToken();
          },
          getPageName: function () {
            return typeof w.customTrkParam !== 'undefined' &&
              typeof w.customTrkParam.pageName !== 'undefined'
              ? w.customTrkParam.pageName
              : d.title;
          },
          log: function (msg) {
            w.console.log(msg);
          },
          setTrkCookie: function (obj) {
            var newCk,
              ckObj,
              i,
              ck = this.cookie.get(c.cookie.inboundTrkParams);

            if (typeof ck === 'string' && ck !== '') {
              try {
                ckObj = JSON.parse(ck);
                for (i in obj) {
                  if (obj.hasOwnProperty(i)) {
                    ckObj[i] = obj[i];
                  }
                }
                newCk = JSON.stringify(ckObj);
              } catch (e) {
                newCk = JSON.stringify(obj);
              }
            } else {
              newCk = JSON.stringify(obj);
            }
            this.cookie.set(c.cookie.inboundTrkParams, newCk);
          },
          cookie: {
            get: function (key) {
              var result = null,
                rdecode = /(%[0-9A-Z]{2})+/g,
                i = 0,
                parts,
                cookie,
                name,
                cookies = d.cookie ? d.cookie.split('; ') : [],
                length = cookies.length;

              for (i; i < length; i += 1) {
                parts = cookies[i].split('=');
                cookie = parts.slice(1).join('=');

                if (cookie.charAt(0) === '"') {
                  cookie = cookie.slice(1, -1);
                }

                name = parts[0].replace(rdecode, decodeURIComponent);
                cookie = cookie.replace(rdecode, decodeURIComponent);

                if (key === name) {
                  result = cookie;
                  break;
                }
              }

              return result;
            },
            set: function (key, val, days, mins) {
              var expires = '',
                setExpiration = false,
                t = new Date();
              if (typeof days === 'number') {
                t.setMilliseconds(t.getMilliseconds() + days * 864e5);
                setExpiration = true;
              }

              if (typeof mins === 'number') {
                t.setMilliseconds(t.getMilliseconds() + mins * 60000);
                setExpiration = true;
              }

              if (setExpiration) {
                expires = '; expires=' + t.toUTCString();
              }
              var secured = process.env.HTTPS ? '; secure' : '';
              d.cookie = [
                encodeURIComponent(key),
                '=',
                encodeURIComponent(val),
                '; path=/',
                `; domain=${DOMAIN}`,
                secured,
                expires,
              ].join('');
            },
          },
          onLoadTrigger: function (callback) {
            if (w.addEventListener) {
              w.addEventListener('load', callback, false);
            } else if (w.attachEvent) {
              w.attachEvent('onload', callback);
            } else {
              w.onload = callback;
            }
          },
        };
      })(w, d, config),
    );

  return trk;
})(window, document, {
  eventProperties: {
    visitorSessionId: 'VisitorSessionID',
    firstTimeVisit: 'FirstTimeVisit',
    pageUrl: 'PageUrl',
    PageFormFactor: 'Page Form Factor',
    site: 'Site',
    pageName: 'Page Name',
    referrer: 'ReferringUrl',
    jSessionId: 'JSESSIONID',
    clientIP: 'Client IP',
    landingPageUrl: 'Landing Page Url',
    initialReferrerUrl: 'Initial Referrer Url',
    initialReferrerUrlDomain: 'Initial Referrer Url Domain',
    mkt: {
      MarketingSource: 'MarketingSource',
      MarketingMedium: 'MarketingMedium',
      ReferringURLMarketingSource: 'ReferringURLMarketingSource',
      ReferringURLMarketingSubsource: 'ReferringURLMarketingSubsource',
      ReferringURLPublisher: 'ReferringURLPublisher',
      ReferringURLPlatform: 'ReferringURLPlatform',
      ReferringURLCampaignId: 'ReferringURLCampaignId',
      ReferringURLAdGroupId: 'ReferringURLAdGroupId',
      ReferringURLCreativeId: 'ReferringURLCreativeId',
      MarketingKeyword: 'MarketingKeyword',
      MarketingMatchType: 'MarketingMatchType',
      ReferringURLSubId: 'ReferringURLSubId',
      ReferringURLSubId2: 'ReferringURLSubId2',
      ReferringURLSubId3: 'ReferringURLSubId3',
      ReferringURLSubId4: 'ReferringURLSubId4',
      ReferringURLSubId5: 'ReferringURLSubId5',
      TransactionId: 'TransactionId',
      MailingID: 'MailingID',
      RefCode: 'RefCode',
      CampaignName: 'CampaignName',
      AdGroupName: 'AdGroupName',
      KeywordID: 'KeywordID',
      OfferName: 'OfferName',
      Incentivized: 'Incentivized',
      AffiliateName: 'AffiliateName',
      Content: 'Content',
    },
    click: {
      ClickDescription: 'ClickDescription',
      ClickType: 'ClickType',
      ClickTitle: 'ClickTitle',
      NavType: 'NavType',
      NavLocation: 'NavLocation',
      LinkLocation: 'LinkLocation',
      ClickedUrl: 'ClickedUrl',
      ClickValue: 'ClickValue',
      ModuleName: 'ModuleName',
    },
    seenModule: {
      moduleName: 'ModuleName',
    },
  },
  cookie: {
    inboundTrkParams: 'inbound_trk_params',
    newUsr: 'ftusr',
    vid: 'vid',
    jSessId: 'JSESSIONID',
    sessionFirstVisit: 'sess-ftuser',
    clientIP: 'clientIP',
    landingPageUrl: 'landing_page_url',
    initialReferrerUrl: 'initial_referrer_url',
  },
});
