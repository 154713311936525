import {
  REQUIRE_2FA,
  DEVICE_LOAD,
  DEVICE_LOAD_ERROR,
  DEVICE_LOAD_SUCCESS,
  SET_TWO_FACTOR_STEP,
  REQUEST_2FA_SECURITY_CODE,
  SET_TWO_FACTOR_CODE_VALUE,
  SELECT_RESEND_OPTION,
  DISABLE_2FA,
  START_DISABLE,
  COMPLETE_DISABLE,
  FAILURE_DISABLE,
  CLEAR_2FA_MODAL,
  COMPLETE_2FA_SUCCESSFULLY,
  STOP_2FA_PROCESS,
  VERIFY_SSN,
  CLEAN_SSN_ERROR,
  VERIFY_OTP_ERROR,
} from 'types/twofactors.constants';
import {
  DISABLE,
  ENROLL_CTA,
  SET_DISABLE_VERIFY_BUTTON,
  SET_DISABLE_UPDATE_PHONENUMBER_BUTTON,
  CONFIRM_SSN,
  CONFIRM_SSN_ERROR,
  REQUEST_SECURITY_CODE,
} from 'partial/TwoFactorModal/TwoFactorsView.constants';
import { LOGOUT } from 'types/app.constants';
import {
  get2FAVerifiedSessionIdentifier,
  getCStrack,
} from 'helpers/localstorage';

const initialState = {
  doEnroll: false,

  session2FAVerified:
    !!getCStrack() && getCStrack() === get2FAVerifiedSessionIdentifier(),

  step: null,
  codeSentTo: null,
  isActionWaiting: false,
  deviceApiData: null,
  deviceApiDataLoading: false,
  deviceApiDataError: null,

  codeValue: '',
  errorMessage: null,
  resendOption: 'SMS',
  invalidPhoneError: null,

  disableVerifyButton: false,
  disablePhoneNumberButton: false,
  disable2FALoading: false,

  finalRedirectedUrl: null,
  applyPush: false,
  verifySsnMessage: null,
  fullSsn: false,
  showMfaProfileModal: false,
  codeAttempts: 0,
  remainingAttempts: 3,
};

const twoFactorReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case REQUIRE_2FA:
      return Object.assign({}, state, {
        doEnroll: !!action.doEnroll,
        step: ENROLL_CTA,
        isActionWaiting: true,
        requirePromote: action.requirePromote,
        codeValue: '',
        accountInfo: action.accountInfo,
        isCashEnroll: action.isCashEnroll,
        errorMessage: null,
        codeSentTo: null,
        lastPlatformCodeSentTo: null,
        invalidPhoneError: null,
        finalRedirectedUrl: action.finalRedirectedUrl,
        applyPush: action.applyPush,
        showMfaProfileModal: action.showMfaProfileModal,
      });
    case VERIFY_SSN:
      return Object.assign({}, state, {
        doEnroll: !!action.doEnroll,
        step: CONFIRM_SSN,
        isActionWaiting: true,
        requirePromote: action.requirePromote,
        codeValue: '',
        accountInfo: action.accountInfo,
        isCashEnroll: action.isCashEnroll,
        errorMessage: null,
        codeSentTo: null,
        lastPlatformCodeSentTo: null,
        invalidPhoneError: null,
        finalRedirectedUrl: action.finalRedirectedUrl,
        applyPush: action.applyPush,
        verifySsnMessage: null,
        showMfaProfileModal: action.showMfaProfileModal,
      });
    case COMPLETE_2FA_SUCCESSFULLY:
      return Object.assign({}, state, {
        session2FAVerified: true,
        doEnroll: false,
        isActionWaiting: true,
      });
    case STOP_2FA_PROCESS:
      return Object.assign({}, state, {
        isActionWaiting: false,
      });
    case CLEAR_2FA_MODAL:
      return Object.assign({}, state, {
        step: null,
        isActionWaiting: false,
        codeValue: '',
      });
    case DISABLE_2FA:
      return Object.assign({}, state, {
        step: DISABLE,
      });
    case START_DISABLE:
      return Object.assign({}, state, {
        disable2FALoading: true,
      });
    case COMPLETE_DISABLE:
      return Object.assign({}, state, initialState, {
        session2FAVerified: false,
      });
    case FAILURE_DISABLE:
      return Object.assign({}, state, {
        disable2FALoading: false,
      });
    case SET_TWO_FACTOR_STEP:
      return Object.assign({}, state, {
        step: action.step,
        codeSentTo: action.codeSentTo ? action.codeSentTo : state.codeSentTo,
        codeValue: '',
        lastPlatformCodeSentTo: action.lastPlatformCodeSentTo
          ? action.lastPlatformCodeSentTo
          : state.lastPlatformCodeSentTo,
        errorMessage: action.errorMessage ? action.errorMessage : null,
        invalidPhoneError: action.invalidPhoneError
          ? action.invalidPhoneError
          : state.invalidPhoneError,
      });

    case REQUEST_2FA_SECURITY_CODE:
      return Object.assign({}, state, {
        step: REQUEST_SECURITY_CODE,
        codeSentTo: action.codeSentTo ? action.codeSentTo : state.codeSentTo,
        codeValue: '',
        lastPlatformCodeSentTo: action.lastPlatformCodeSentTo
          ? action.lastPlatformCodeSentTo
          : state.lastPlatformCodeSentTo,
        errorMessage: action.errorMessage ? action.errorMessage : null,
        invalidPhoneError: action.invalidPhoneError
          ? action.invalidPhoneError
          : state.invalidPhoneError,
        doEnroll: !!action.doEnroll,
        isActionWaiting: true,
        requirePromote: action.requirePromote,
        accountInfo: action.accountInfo,
        isCashEnroll: action.isCashEnroll,
        finalRedirectedUrl: action.finalRedirectedUrl,
        applyPush: action.applyPush,
        verifySsnMessage: null,
        showMfaProfileModal: action.showMfaProfileModal,
      });

    case DEVICE_LOAD:
      return Object.assign({}, state, {
        deviceApiData: null,
        deviceApiDataLoading: true,
        deviceApiDataError: null,
      });
    case DEVICE_LOAD_SUCCESS:
      return Object.assign({}, state, {
        deviceApiData: action.payload,
        deviceApiDataLoading: false,
        deviceApiDataError: null,
      });
    case DEVICE_LOAD_ERROR:
      return Object.assign({}, state, {
        deviceApiData: null,
        deviceApiDataLoading: false,
        deviceApiDataError: action.error,
      });
    case SET_TWO_FACTOR_CODE_VALUE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
        errorMessage: action.errorMessage,
      });
    case VERIFY_OTP_ERROR:
      return Object.assign({}, state, {
        codeAttempts: state.codeAttempts + 1,
        remainingAttempts: action.remainingAttempts,
      });
    case SELECT_RESEND_OPTION:
      return Object.assign({}, state, {
        resendOption: action.resendOption,
      });
    case SET_DISABLE_VERIFY_BUTTON:
      return Object.assign({}, state, {
        disableVerifyButton: action.disableVerifyButton,
      });
    case SET_DISABLE_UPDATE_PHONENUMBER_BUTTON:
      return Object.assign({}, state, {
        disablePhoneNumberButton: action.disablePhoneNumberButton,
      });
    case CONFIRM_SSN_ERROR:
      return Object.assign({}, state, {
        verifySsnMessage: action.verifySsnMessage,
        fullSsn: false,
      });
    case CLEAN_SSN_ERROR:
      return Object.assign({}, state, {
        verifySsnMessage: null,
      });
    case LOGOUT:
      return Object.assign({}, initialState, { session2FAVerified: false });
    default:
      return state;
  }
};

export default twoFactorReducer;
