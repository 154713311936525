import {
  adwordsCookieConfig,
  mktCookieConfig,
  mktTrackingCookieName,
  IR_CLICK_ID,
  E2E_SIGNUP_REDIRECT,
} from 'pages/SignupPage/types/marketingAttribution.constants';
import { getItem, removeItem, setItem, setRefCode } from './localstorage';
import { getCookieValue, setCookieWithExpiration } from 'helpers/cookie.helper';

export const getMarketingCookies = () => {
  //Need to be on HTTPS to read these cookies
  let mktAttributes = {};
  const promoCode = getDMPromoCode();
  const getConfigAttributes = (config, inboundParamsCookieObj) => {
    for (const property in config) {
      let paramName = config[property];
      if (inboundParamsCookieObj[paramName]) {
        if (paramName === 'marketingUrl' && promoCode) {
          mktAttributes[property] =
            inboundParamsCookieObj[paramName] + '&sub_id=' + promoCode;
        } else {
          mktAttributes[property] = inboundParamsCookieObj[paramName];
        }
      }
    }
  };

  try {
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj = JSON.parse(
      decodeURIComponent(inboundParamsCookie),
    );
    getConfigAttributes(mktCookieConfig, inboundParamsCookieObj);
    if (!mktAttributes.campaignId) {
      getConfigAttributes(adwordsCookieConfig, inboundParamsCookieObj);
    }

    processAdditionalCookies(mktAttributes);
  } catch (err) {
    //logger.error(`Error parsing "${mktTrackingCookieName}" cookie value`, err);
  }

  return mktAttributes;
};

const processAdditionalCookies = mktAttributes => {
  const additionalCookies = ['cta', 'lastIn', 'experimentIds'];
  additionalCookies.forEach(cookieName => {
    const cookieValue = getCookieValue(cookieName);
    const decodedValue = decodeURIComponent(cookieValue);
    if (decodedValue) {
      switch (cookieName) {
        case 'lastIn': {
          mktAttributes[cookieName] = JSON.stringify(decodedValue);
          break;
        }
        case 'cta': {
          const ctaObj = JSON.parse(decodedValue);
          Object.keys(ctaObj).forEach(key => {
            mktAttributes[key] = ctaObj[key];
          });
          break;
        }
        case 'experimentIds': {
          const experimentIdsArray = JSON.parse(decodedValue);
          mktAttributes['experimentId'] = experimentIdsArray.map(exp => exp.id);
          break;
        }
        default:
          break;
      }
    }
  });
};
export const checkMarketingRefCode = () => {
  try {
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj = JSON.parse(
      decodeURIComponent(inboundParamsCookie),
    );

    const refCode = inboundParamsCookieObj.RefCode;
    if (refCode) {
      setRefCode(refCode);
    }
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const clearMarketingRefCode = () => {
  try {
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj = JSON.parse(
      decodeURIComponent(inboundParamsCookie),
    );
    const refCode = inboundParamsCookieObj.RefCode;
    if (refCode) {
      delete inboundParamsCookieObj.RefCode;
      setCookieWithExpiration(
        mktTrackingCookieName,
        JSON.stringify(inboundParamsCookieObj),
        null,
        null,
        null,
        true,
      );
    }
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const isTPHSource = () => {
  const src = getCookieValue('src');
  if (src && src.toLowerCase().indexOf('thepennyhoarder.com') >= 0) {
    return true;
  } else {
    return false;
  }
};

const getMktCookieObj = () => {
  try {
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj = JSON.parse(
      decodeURIComponent(inboundParamsCookie),
    );

    return inboundParamsCookieObj;
  } catch (err) {
    console.log(`Error parsing Mkt cookie obj`, err);
  }
  return null;
};

export const clearMarketingCookieAttr = attributeName => {
  try {
    const cookieObj = getMktCookieObj();
    const value = cookieObj[attributeName];
    if (value) {
      delete cookieObj[attributeName];
      setCookieWithExpiration(
        mktTrackingCookieName,
        JSON.stringify(cookieObj),
        null,
        null,
        null,
        true,
      );
    }
  } catch (err) {
    console.log('ERROR', err);
  }
};

export const getMarketingCookieAttr = attributeName => {
  const cookieObj = getMktCookieObj();
  return cookieObj ? cookieObj[attributeName] : null;
};

export const getEncryptedEmail = () => {
  const hasOfferName =
    getMarketingCookieAttr('OfferName') ===
    'Desktop_FreeCreditScore_NonIncent_TPH_Overlay';
  let email =
    isTPHSource() && hasOfferName
      ? getMarketingCookieAttr('ReferringURLSubId3')
      : null;
  if (email) {
    email = email.replace(/ /g, '+');
  }
  return email;
};

export const getAndClearEncryptedEmail = () => {
  let email = getEncryptedEmail();
  if (email) {
    setItem('isEncryptedEmail', true);
    clearMarketingCookieAttr('ReferringURLSubId3');
  } else {
    removeItem('isEncryptedEmail');
  }
  return email;
};

export const getAndClearPerformEncryptedEmail = keepAttribute => {
  let email = getMarketingCookieAttr('ReferringURLSubId3');
  if (email) {
    email = email.replace(/ /g, '+');
    if (!keepAttribute) {
      clearMarketingCookieAttr('ReferringURLSubId3');
    }
  }
  return email;
};

export const getInitialReferrer = () => {
  const referrer = getItem('initialReferrer');
  return referrer?.toLowerCase();
};

export const getDMPromoCode = () => {
  return getMarketingCookieAttr('PromoCode');
};

export const getPromoCodeTracking = () => {
  const promoCode = getDMPromoCode();
  return promoCode ? promoCode : 'no_promocode';
};

export const getMarketingAttributes = () => {
  const mktAttrList = [
    'MarketingSource',
    'MarketingMedium',
    'ReferringURLMarketingSource',
    'ReferringURLMarketingSubsource',
    'CampaignName',
    'AffiliateName',
  ];
  let mktAttributes = {};
  mktAttrList.forEach(attribute => {
    const attributeFromCookie = getMarketingCookieAttr(attribute);
    if (attributeFromCookie) {
      mktAttributes[attribute] = attributeFromCookie;
    }
  });

  return mktAttributes;
};

export const getMarketingUrlE2eSignUpRedirect = (
  _getMarketingCookies = getMarketingCookies,
) => {
  const marketingCookies = _getMarketingCookies();
  const url = marketingCookies[mktCookieConfig?.marketingUrl];
  const newUrl = url ? new URL(url) : null;
  return newUrl?.searchParams?.get(E2E_SIGNUP_REDIRECT);
};

/**
 * Refer to https://creditsesame.atlassian.net/browse/EPOD-1904
 * As Raphael Attar and Danny Trujillo knowledge transferred to me,
 * only sending registered customers information to Impact when 'inbound_trk_params' cookie has 'marketingUrl' and its value includes 'irclickid'
 * This function is to check if a 'marketingUrl' contains 'irclickid' property
 * @returns boolean
 */
export const doesMarketingUrlIncludeIrclickid = (
  _getMarketingCookies = getMarketingCookies,
) => {
  const marketingCookies = _getMarketingCookies();
  const url = marketingCookies[mktCookieConfig?.marketingUrl];
  const newUrl = url ? new URL(url) : null;
  return newUrl?.searchParams?.has(IR_CLICK_ID);
};
